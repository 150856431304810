<template>
    <app-transition>
        <Dialog
            class="AccessCamera"
            :only-text="true"
            @close="handleDeny"
        >
            <v-row text-center="">
                <v-col>
                    <div class="AccessCamera-title">
                        {{ $t('dialogs.access_camera_title') }}
                    </div>
                    <div class="AccessCamera-description">
                        {{ $t('dialogs.access_denied_camera_description') }}
                    </div>
                </v-col>
            </v-row>
        </Dialog>
    </app-transition>
</template>

<script>
import Dialog from "@/components/app/form/Dialog";

export default {
    name: "AccessDeniedCameraDialog",
    methods: {
        handleClose() {
            this.$emit('close');
        },
        handleDeny() {
            this.handleClose()
        },
        handleAllow() {
            this.$emit('allowed');
        },
    },
    components: {
        Dialog,
    },
}
</script>

<style scoped lang="scss">
@import '@/sass/modules/_variables';

.AccessCamera {
    &-title, &-description {
        font-size: 18px;
        font-style: italic;
        width: 100%;
    }
    &-btn.v-btn {
        border-radius: 8px;
        padding: 13px 53px;

        span {
            line-height: 19px;
        }
    }
}
</style>
