<template>
    <app-transition>
        <Dialog
            class="CountdownMessage"
            :only-text="true"
            @close="handleClose"
        >
            <v-row text-center="">
                <v-col>
                    <div class="CountdownMessage-title">
                        {{ $t('dialogs.countdown_text') }}
                    </div>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col>
                    <v-btn outlined block class="CountdownMessage-btn text-capitalize" @click="handlePhoto">
                        {{ $t('dialogs.photo') }}
                    </v-btn>
                    <v-btn outlined block class="CountdownMessage-btn mt-2 text-capitalize" @click="handleVideo">
                        {{ $t('dialogs.video') }}
                    </v-btn>
                </v-col>
            </v-row>
        </Dialog>
    </app-transition>
</template>

<script>
import Dialog from "@/components/app/form/Dialog";

export default {
    name: "CountdownDialog",
    methods: {
        handleClose() {
            this.$emit('close');
        },
        handlePhoto() {
            this.$emit('change', 'photo');
        },
        handleVideo() {
            this.$emit('change', 'video');
        },
    },
    components: {
        Dialog,
    },
}
</script>

<style scoped lang="scss">
@import '@/sass/modules/_variables';

.CountdownMessage {
    &-title {
        font-size: 18px;
        font-style: italic;
        width: 100%;
    }
    &-btn.v-btn {
        border-radius: 8px;
        padding: 13px 53px;

        span {
            line-height: 19px;
        }
    }
}
</style>
