<template>
    <div v-if="time > 0" class="latency-timer">
        {{ time }}
    </div>
</template>

<script>
export default {
    name: "LatencyTimer",
    data() {
        return {
            timer: false,
            time: 0,
        }
    },
    props: {
        seconds: {
            type: Number,
            default: 0
        }
    },
    methods: {
      setTimer() {
          this.time = this.seconds
          if (this.timer) {
              clearTimeout(this.timer)
          }
          this.timer = setInterval(() => {
              if (this.time) {
                  this.time--
              }
          }, 1000)
      }
    },
    watch: {
        time() {
            if (this.time === 0) {
                clearTimeout(this.timer)
                this.$emit('timeUp')
            }
        },
        seconds(value) {
            if (value) {
                this.setTimer()
            }
        }
    },
    mounted() {
        if (this.seconds) {
            this.setTimer()
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/sass/modules/_variables.scss';

.latency-timer {
    font-size: 5rem;
    color: white;
    height: 100%;
    width: 100%;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
}
</style>
