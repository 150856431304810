<template>
    <div>
<!--        <div v-if="zoomLevel" class="row justify-center align-center my-4">-->
<!--            <button-->
<!--                class="zoom__button"-->
<!--                @click="$emit('setZoom', zoomLevel - 0.1)"-->
<!--                @dblclick="$emit('setZoom', zoomLevel - 0.2)"-->
<!--            >-</button>-->
<!--            <div class="px-4">{{ zoomLevel }}x</div>-->
<!--            <button-->
<!--                class="zoom__button"-->
<!--                @click="$emit('setZoom', zoomLevel + 0.1)"-->
<!--                @dblclick="$emit('setZoom', zoomLevel + 0.2)"-->
<!--            >+</button>-->
<!--        </div>-->
        <v-row justify="between">
            <v-col
                :class="{
                    active: isFrontCamera
                }"
                @click="handleChangeCamera"
            >
                <change-camera/>
            </v-col>
            <v-col
                :class="{
                    active: isActiveCameraTimer
                }"
                @click="handleCameraTimer"
            >
                <camera-timer/>
            </v-col>
            <v-col
                :class="{
                    active: isActiveFlashlight
                }"
                @click="handleFlashlight"
            >
                <flashlight />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import CameraTimer from "@/assets/images/icons/camera-timer.svg?inline";
import ChangeCamera from "@/assets/images/icons/change-camera.svg?inline";
import Flashlight from "@/assets/images/icons/flashlight.svg?inline";

export default {
    name: "CameraControlBlock",
    data() {
        return {
            isActiveFlashlight: false,
        }
    },
    props: {
      isFrontCamera: {
          type: Boolean,
          default: false,
      },
      isActiveCameraTimer: {
          type: Boolean,
          default: false,
      },
      // zoomLevel: {
      //     type: Number,
      //     default: null,
      // },
    },
    components: {
        CameraTimer,
        ChangeCamera,
        Flashlight,
    },
    methods: {
        handleChangeCamera() {
            this.isActiveFlashlight = false
            this.$emit('changeCamera')
        },
        handleCameraTimer() {
            this.$emit('cameraTimer')
        },
        handleFlashlight() {
            this.isActiveFlashlight = !this.isActiveFlashlight
            this.$emit('flashlight')
        }
    },
}
</script>

<style scoped lang="scss">
svg {
    path {
        fill: #FFFFFF;
    }
}

.active {
    svg {
        path {
            fill: #A46A1F;
        }
    }
}

.zoom__button {
    background: white;
    color: black;
    border-radius: 50%;
    padding: 4px;
    width: 32px;
}
</style>
