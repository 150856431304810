/<template>
    <v-btn
        fab
        id="record-button"
        v-bind="$attrs"
        v-on="$listeners"
        :class="{
            'touched': touched
        }"
        @mousedown="handleMouseDown"
        @mouseup="handleMouseUp"
        @touchstart="handleTouchStart"
        @touchend="handleTouchEnd"
        @touchmove="handleTouchMove"
    />
</template>

<script>
export default {
    name: "RecordButton",
    data: () => ({
        clearableInterval: '',
        isVideoStart: false,
        touched: false,
        zoomFactor: 1.0,
        minZoom: 0.5,
        maxZoom: 4.0,
        touchStartY: null,
    }),
    props: {
        disableVideo: {
            type: Boolean,
            default: false,
        },
        useTimer: {
            type: Boolean,
            default: false,
        }
    },
    methods: {
        handleTouchStart(event) {
            this.touched = true
            if (this.disableVideo || this.useTimer) {
                return
            }

            this.touchStartY = event.touches[0].clientY;
            this.clearableInterval = setTimeout(() => {
                    this.isVideoStart = true;
                    this.$emit('videoStart')
                }, 1000
            )
        },
        handleTouchEnd() {
            this.touched = false
            if (this.isVideoStart === true || this.useTimer) {
                this.$emit('videoEnd')
                this.isVideoStart = false;
            } else {
                this.$emit('photoShoot')
            }

            clearTimeout(this.clearableInterval)
        },
        handleTouchMove(event) {
            if (!this.isVideoStart) return;

            const currentY = event.touches[0].clientY;
            const deltaY = currentY - this.touchStartY;

            if (deltaY > 0) {
                // Move finger down: Zoom out
                this.zoomFactor = Math.max(this.minZoom, this.zoomFactor - 0.05);
                this.touchStartY = currentY;
            } else if (deltaY < 0) {
                // Move finger up: Zoom in
                this.zoomFactor = Math.min(this.maxZoom, this.zoomFactor + 0.05);
                this.touchStartY = currentY;
            }
            this.$emit('zoom-change', this.zoomFactor)
        },
        handleMouseDown() {
            this.handleTouchStart()
        },
        handleMouseUp() {
            this.handleTouchEnd()
        },
    }
}
</script>

<style scoped lang="scss">
@import '@/sass/modules/_variables.scss';

#record-button {
    width: 74px;
    height: 74px;
    border: double 4px transparent;
    border-radius: 64px;
    background-image: linear-gradient(white, white), $accent-background;
    background-origin: border-box;
    background-clip: content-box, border-box;
    transition: 0.5s;
}

#record-button.touched {
    width: 84px;
    height: 84px;
    border: double 9px transparent;
    border-radius: 64px;
    background-image: linear-gradient(#bebebe, #bebebe), $accent-background;
    background-origin: border-box;
    background-clip: content-box, border-box;
}
</style>
