<template>
    <camera-layout>
        <latency-timer
            v-if="isStartLatencyTimer"
            :seconds="latencyTime"
            @timeUp="handleLatencyTimeUp"
        />

        <video
            ref="video"
            autoplay
            muted
            playsinline
            class="background"
            :class="{
                'mirrored': isFrontCamera
            }"
        />

        <div v-if="isShowControls" class="closeButton-wrapper">
            <close-button @click="handleCloseButton"/>
        </div>
        <div v-if="isShowControls" class="timer">
            {{ latency }}
        </div>
        <div style="position: fixed; bottom: 0; width: 100%; padding: 0 20px;">
            <div class="record-button-container mt-auto">
                <v-row class="flex-column" align="center" justify="center">
                    <div v-if="videoTime !== false" class="video-timer mb-1">
                        {{ videoTimeFormat }}
                    </div>
                    <record-button
                       :disable-video="disableVideo"
                       :useTimer="useTimer"
                       @click.stop.prevent
                       @touchstart.stop.prevent
                       @videoStart="videoStart"
                       @photoShoot="handlePhotoShoot"
                       @zoom-change="handleZoomChange"
                       @videoEnd="videoEnd"
                    />
                </v-row>
            </div>
            <div class="control-button-block">
                <camera-control-block
                    v-if="isShowControls"
                    :isFrontCamera="isFrontCamera"
                    :isActiveCameraTimer="isActiveCameraTimer"
                    @changeCamera="changeCamera"
                    @cameraTimer="handleCameraTimer"
                    @flashlight="handleFlashlight"
                />
            </div>
        </div>
        <canvas
            class="helper-canvas"
            ref="helper"
            style="position: absolute; height: 100vh; width: auto; top: -4000vh;"
            :width="widthVideo"
            :height="heightVideo"
        />
        <flashlight :show-flashlight="showFlashlight"/>
        <countdown-dialog
            v-if="this.isShowCountdownDialog"
            @change="changeDoAfterExecTimer"
            @close="handleCloseCountdownDialog"
        />
        <access-camera-dialog
            v-if="this.isShowAccessCameraDialog"
            @allowed="handleAllowedCamera"
            @close="handleCloseAccessCameraDialog"
        />
        <access-denied-camera-dialog
            v-if="this.isShowAccessDeniedCameraDialog"
            @close="handleCloseAccessDeniedCameraDialog"
        />
    </camera-layout>

</template>

<script>
import RecordButton from "@/components/app/posting/RecordButton";
import CameraLayout from "@/layouts/app/postingLayout/CameraLayout";
import CameraControlBlock from "@/components/app/posting/CameraControlBlock";
import LatencyTimer from "@/components/app/posting/LatencyTimer";
import Flashlight from "@/components/app/posting/Flashlight";
import CloseButton from "@/components/app/button/CloseButton";
import {LATENCY_TIME_STEP, STORY_ITEM_LENGTH_TIME} from "@/configs/constants";
import {mapMutations} from "vuex";
import moment from "moment";
import CountdownDialog from "@/components/app/dialogs/CountdownDialog";
import AccessCameraDialog from "@/components/app/dialogs/AccessCameraDialog";
import AccessDeniedCameraDialog from "@/components/app/dialogs/AccessDeniedCameraDialog";

export default {
    name: "RecordScreen",
    emits: ['change-camera'],
    data: () => ({
        stopVideo: false,
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
        canvas: '',
        widthVideo: 0,
        heightVideo: 0,
        widthVideoContent: 0,
        heightVideoContent: 0,
        core: '',
        latencyTime: 0,
        isStartLatencyTimer: 0,
        latencyTimer: false,
        videoTime: false,
        videoTimer: false,
        isActiveFlashlight: false,
        showFlashlight: false,
        isActiveTorch: false,
        isShowCountdownDialog: false,
        isShowAccessCameraDialog: false,
        isShowAccessDeniedCameraDialog: false,
        useTimer: false,
        doAfterExecTimer: 'photo',
    }),
    props: {
        disableAudio: {
            type: Boolean,
            default: false,
        },
        disableVideo: {
            type: Boolean,
            default: false,
        },
        isFrontCamera: {
            type: Boolean,
            default: false
        },
    },
    components: {
        AccessDeniedCameraDialog,
        AccessCameraDialog,
        CountdownDialog,
        RecordButton,
        CameraLayout,
        CameraControlBlock,
        CloseButton,
        LatencyTimer,
        Flashlight,
    },
    computed: {
        video() {
            return this.$refs.video
        },
        latency() {
            return this.latencyTime > 0 ? this.latencyTime + 's' : ''
        },
        videoTimeFormat() {
            if (this.videoTime !== false) {
                return moment.utc(this.videoTime * 1000).format('mm:ss');
            }
            return ''
        },
        // isMirrored() {
        //     return this.isFrontCamera
        // },
        isShowControls() {
            return !(this.isStartLatencyTimer || this.videoTimer)
        },
        isActiveCameraTimer() {
            return this.latencyTime > 0
        },
    },
    watch: {
        isShowAccessDeniedCameraDialog(val) {
            if (val && this.isShowAccessCameraDialog) {
                this.isShowAccessCameraDialog = false
            }
        },
    },
    methods: {
        handleCloseButton() {
            if (this.core) {
                this.core.stopCamera()
            }
            this.setStep(3)
            this.$emit('change-camera', false)
        },
        videoStart() {
            this.videoTime = 0
            this.setType('video')
            this.videoTimer = setInterval(() => {
                this.videoTime++
                if (this.videoTime % STORY_ITEM_LENGTH_TIME === 0) {
                    this.videoEnd()
                }
            }, 1000)
            this.core.videoInit()
            this.core.startRecordVideo()
            if (this.isFrontCamera && this.isActiveFlashlight) {
                this.showFlashlight = true;
            }
        },
        async handleZoomChange(zoomLevel) {
            await this.core.setZoom(zoomLevel);
        },
        videoEnd() {
            this.showTimer = false
            clearTimeout(this.videoTimer)
            if (this.videoTime !== false && this.videoTime > 0) {
                this.core.stopRecordVideo(async (blob) => {
                    await this.setVideo({blob})
                    this.setVideoDuration(this.videoTime)
                    await this.setVideoDataUrl(URL.createObjectURL(blob))
                    this.core.stopCamera()
                    this.setStep(1)
                })

                this.showFlashlight = false;
            } else {
                this.photoShoot()
            }
        },
        handlePhotoShoot() {
            this.isStartLatencyTimer = 1
            if (!this.latencyTime) {
                this.photoShoot()
            }
        },
        photoShoot() {
            let delay = 0;
            if (this.isFrontCamera && this.isActiveFlashlight) {
                this.showFlashlight = true;
                delay = 300
            }

            setTimeout(() => {
                this.setType('photo')
                this.core.toWidth = this.$refs.video.clientWidth
                this.core.toHeight = this.$refs.video.clientHeight
                this.core.frameCaptureBlob(blob => this.setImage({image: blob}))
                this.setImageDataUrl({
                    image: this.core.frameCapture(),
                    width: this.width,
                    height: this.height,
                })
                this.core.stopCamera()
                this.setStep(1)
                this.showFlashlight = false;
            }, delay)
        },
        async changeCamera(emitUpdate = true) {
            let status = await this.core.changeCamera()
            if (status) {
                this.isActiveTorch = false
                this.isActiveFlashlight = false
                if (emitUpdate) {
                    this.$emit('change-camera', !this.isFrontCamera)
                }
            }
        },
        handleCameraTimer() {
            this.latencyTime = !this.latencyTime ? LATENCY_TIME_STEP : 0
            if (this.latencyTime) {
                this.isShowCountdownDialog = true
            }
        },
        handleFlashlight() {
            this.isActiveTorch = !this.isActiveTorch
            this.isActiveFlashlight = !this.isActiveFlashlight
            
            this.core.useTorch(this.isActiveTorch)
        },
        handleLatencyTimeUp() {
            this.isStartLatencyTimer = 0
            this.useTimer = true
            if (this.doAfterExecTimer === 'photo') {
                this.photoShoot()
            }
            if (this.doAfterExecTimer === 'video') {
                this.videoStart()
            }
        },
        handleCloseCountdownDialog() {
            this.latencyTime = 0
            this.isShowCountdownDialog = false
        },
        handleCloseAccessCameraDialog() {
            this.isShowAccessCameraDialog = false
            this.setStep(3)
        },
        handleCloseAccessDeniedCameraDialog() {
            this.isShowAccessDeniedCameraDialog = false
            this.setStep(3)
        },
        handleAllowedCamera() {
            this.isShowAccessCameraDialog = false
            this.loadCamera()
        },
        async loadCamera() {
            await this.setResolutions();

            if (this.isFrontCamera) {
                await this.changeCamera(false);
            }

            await this.core.checkZoomSupport();

            const self = this;
            window.addEventListener('orientationchange', function () {
                self.setResolutions();
            });
        },
        changeDoAfterExecTimer(value) {
            this.doAfterExecTimer = value
            this.isShowCountdownDialog = false
        },
        async setResolutions() {
            if (this.$refs.video) {
                this.width = this.$refs.video.clientWidth
                this.height = this.$refs.video.clientHeight
            }
            this.core = this.$core.storyCore;
            this.core.init(this.$refs.helper, this.$refs.video, !this.disableAudio)
            try {
                await this.core.showVideoFromCamera(([w, h]) => {
                    this.widthVideo = w
                    this.heightVideo = h
                })
            } catch (e) {
                this.isShowAccessDeniedCameraDialog = true
            }

        },
        ...mapMutations([
            'hideCameraScreen',
            'setStep',
            'setImage',
            'setVideo',
            'setType',
            'setImageDataUrl',
            'setVideoDataUrl',
            'setVideoDuration',
        ])
    },
    async mounted() {
        await this.loadCamera()

        if (!this.core.stream && !this.isShowAccessCameraDialog && !this.isShowAccessDeniedCameraDialog) {
            this.isShowAccessCameraDialog = true;
        } else if (this.core.stream) {
            this.isShowAccessCameraDialog = false;
        }
    }
}
</script>

<style scoped lang="scss">
.mirrored {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}
.record-button-container,
.control-button-block,

.timer {
    text-align: center;
    text-align: -webkit-center;
    z-index: 10;
}

#cameraFileInput {
    display: none;
}

#pictureFromCamera {
    width: 100%;
    height: auto;
    margin-top: 16px;
}

.btn {
    display: inline-block;
    background-color: #00b531;
    color: white;
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}

.btn:hover {
    filter: brightness(0.9);
}

.closeButton-wrapper {
    position: absolute;
    width: 3em;
    left: 2em;
    top: 3em;
    z-index: 10;
}

.control-button-block {
    position: relative;
    margin-bottom: 20px;
    margin-top: 20px;
}

.timer {
    font-size: 1.7em;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.background.full-width {
    background: black;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    height: 100vh;
    width: 100vw;
    min-height: 56.25vw;
}

.background {
    background: black;
    position: absolute;
    z-index: -1;
    object-fit: cover;
    width: 100vw !important;
    min-width: 100vw !important;
    max-width: 100vw !important;
    height: 100vh !important;
    min-height: 100vh !important;
    max-height: 100vh !important;
}

video {
    width: 100vw !important;
    min-width: 100vw !important;
    max-width: 100vw !important;
    height: 100vh !important;
    min-height: 100vh !important;
    max-height: 100vh !important;
}

.video-timer {
    color:#FE4811;
    position: relative;
    display: flex;
    align-items: center;

    &::before {
        position: absolute;
        left: -18px;
        content: "";
        width: 10px;
        height: 10px;
        background-color: #FE4811;
        border-radius: 10px;
        margin-right: 10px;
    }

    &::after {
        position: absolute;
        left: -22px;
        content: "";
        width: 18px;
        height: 18px;
        border: 1px solid #FE4811;
        border-radius: 10px;
        margin-right: 10px;
    }
}
</style>
