<template>
    <div v-on="$listeners">

        <div id="camera-main-screen" class="d-flex flex-column">

            <slot></slot>
        </div>

    </div>
</template>

<script>
export default {
    name: "CameraLayout",
    props: {
        showVideo: {
            type: Boolean,
        },
        showPhotoResult: {
            type: Boolean,
        }
    },
    mounted() {


       /* let canvas = this.$refs.canvas;
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {

            // Not adding `{ audio: true }` since we only want video now
            navigator.mediaDevices.getUserMedia({video: true}).then(function (stream) {
                //video.src = window.URL.createObjectURL(stream);
                video.srcObject = stream;
                video.play();
            });
        }else{
            alert('DONT SUPPORTED')
        }
        setInterval(()=>canvas.getContext('2d').drawImage(video,0,0,video.videoWidth / 2, video.videoHeight / 2), 0)*/
    }
}
</script>

<style scoped lang="scss">
#camera-main-screen {
    background-color: black;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    overflow: hidden;
    animation: fadeIn .2s;
}
</style>
