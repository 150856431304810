<template>
    <div v-if="showFlashlight" class="flashlight-wrapper">
    </div>
</template>

<script>
export default {
    name: "Flashlight",
    data() {
        return {}
    },
    props: {
        showFlashlight: {
            type: Boolean,
            default: false,
        },
    },
    methods: {},
    watch: {},
    mounted() {
    }
}
</script>

<style scoped lang="scss">
@import '@/sass/modules/_variables.scss';

.flashlight {
    &-wrapper {
        background: rgba(255, 255, 255, 0.75);
        backdrop-filter: brightness(200%);
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
    }
}
</style>
